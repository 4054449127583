$dark-navy: #112C57;
$dark-blue: #035EAA;
$light-blue: #27A9E1;
$light-orange: #F05924;
$dark-orange: #B72823;

.footer-wrapper {
    // width: 100%;
    padding: 3em;
    background-color: $dark-navy;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    color: $light-blue;
}

.footer-link {
    color: inherit;
    text-decoration: none;
}