$dark-navy: #112C57;
$dark-blue: #035EAA;
$light-blue: #27A9E1;
$light-orange: #F05924;
$dark-orange: #B72823;

html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'LinkedNow';
    src: url('../assets/LinkedNow.otf') format('truetype');
}

@font-face {
    font-family: 'Moonshine';
    src: url('../assets/Moonshine.otf') format('truetype');
}



// Banner

.home-wrapper {
    padding: 8em 0 0 0;


    @media screen and (max-width: 980px) {
        padding: 12em 0 0 0;
    }
}

.banner-wrapper {
    background: linear-gradient(90deg, $light-blue 0%, $dark-blue 100%);
    // padding: 1em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 0 5em 0;

    @media screen and (max-width: 980px) {
        display: block;
    }
}

.header-tagline {
    color: white;
    font-size: 2em;
    padding: 2em;

    @media screen and (max-width: 980px) {
        font-size: 1em;
    }
}

.header-tagline h1 {
    font-family: LinkedNow;
    text-transform: uppercase;
}

.header-tagline h4 {
    // font-family: Moonshine;
    // letter-spacing: .15em;
}

.header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

// How It Works

.hiw-step-wrapper.hidden {
    display: none;
}

.hiw-step-wrapper.visible {
    display: block;
}

.technique-btn {
    border: 1px solid rgb(224, 224, 224);
    padding: 2em;
    background-color: transparent;
    border-radius: 5px;
    transition: all .2s ease-in-out;
    width: 35%;

    @media screen and (max-width: 980px) {
        max-width: 75%;
        margin: 2em 0;
        width: auto;
    }
}

.technique-btn:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(214, 214, 214, 0.726);
}

.technique-btn.selected {
    border: 5px solid $light-orange;
}

.technique-btn img {
    border-radius: 5px;
    width: 50%;
    // height: ;
    object-fit: cover;
}

.technique-title {
    text-transform: uppercase;
    color: $dark-blue;
    font-family: LinkedNow;
    font-size: 2em;
    margin: .75em 0 0 0;
}

.hiw-step {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 6em 0;

    @media screen and (max-width: 980px) {
        margin: 3em 0;
    }
}

.hiw-step img {
    // max-width: 50%;
    object-fit: cover;

    @media screen and (max-width: 980px) {
        max-width: 50%;
        margin: 3em 0;
    }
}

.techniques-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin: 5em 0;

    @media screen and (max-width: 980px) {
        flex-direction: column;
        align-items: center;
        margin: 2em 0;
    }
}

.step-info {
    max-width: 25%;
    padding: 0 3em;

    @media screen and (max-width: 980px) {
        max-width: none;
        text-align: center;
    }
}

.step-info h3 {
    color: $dark-blue;
    font-size: 3em;
    font-family: LinkedNow;

    @media screen and (max-width: 980px) {
        margin: .5em 0;
    }
}

.step-info h6 {
    color: $light-orange;
    font-family: Moonshine;
    text-transform: uppercase;
    font-size: 1.5em;
    margin: 1em 0;
}

.hiw-subtitle {
    text-align: center;
    color: $light-blue;
    opacity: 70%;
    position: relative;
    z-index: -50;
}

.hiw-step.reverse {
    flex-direction: row-reverse;
}

.hiw-video {
    display: flex;
    justify-content: center;
}

// Benefits

.benefits-wrapper {
    text-align: center;
    margin: 5em 0 0 0;
}

.benefits-wrapper h3 {
    font-family: LinkedNow;
    text-transform: uppercase;
    color: $dark-navy;
}

.benefit-title,
.hiw-title {
    font-size: 2em;
    text-align: center;
    font-family: LinkedNow;
    text-transform: uppercase;
    color: $dark-navy;

    @media screen and (max-width: 980px) {
        font-size: 1.5em;
    }
}

.benefits-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center; // Centers items vertically in each cell
    justify-items: center; // Centers items horizontally in each cell

    @media screen and (max-width: 980px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.benefit {
    max-width: 50%;
    transition: all .2s ease-in-out;
}

.benefit:hover {
    // transform: scale(1.1);
}

.benefit-icon {
    height: 10em;
    width: 10em;
    // background-color: $dark-navy;
    margin: 3em 0;

    @media screen and (max-width: 980px) {
        width: 100%;
    }
}

.perk-title {
    color: $dark-blue !important;
}

// Schedule

.schedule-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    padding: 2em 0;
    margin: 3em 0 0 0;

    @media screen and (max-width: 980px) {
        display: flex;
        flex-direction: column-reverse;
        margin: 1em 0 0 0;
    }
}

.schedule-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 1em;
}

.schedule-title {
    font-size: 3em;
    max-width: 50%;
    font-family: LinkedNow;
    color: $dark-navy;

    @media screen and (max-width: 980px) {
        max-width: none;
        margin-top: 0;
    }
}

.schedule-container.owner {
    align-items: flex-end;

    @media screen and (max-width: 980px) {
        align-items: center;
    }
}

.schedule-container.info {
    align-items: flex-start;
    padding: 0 0 10em 3em;

    @media screen and (max-width: 980px) {
        padding: 1em;
        align-items: center;
        text-align: center;
    }
}

.schedule-now-btn {
    background: linear-gradient(90deg, $light-orange 0%, $dark-orange 100%);
    padding: .5em 1em;
    border-radius: 5px;
    text-transform: uppercase;
    color: white !important;
    text-decoration: none;
    font-size: 1.75em;
    transition: all .2s ease-in-out;
    font-family: Moonshine;
    letter-spacing: .1em;
}

.schedule-now-btn:hover {
    filter: brightness(.75);
}

.owner-picture {
    width: 50%;
    // height: 100%;
    object-fit: cover;
}

button {
    border: none;
}