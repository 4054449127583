$dark-blue: #112C57;
$light-blue: #27A9E1;
$light-orange: #F05924;
$dark-orange: #B72823;

.nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    padding: 1em 0;
    border-bottom: 1px solid rgb(212, 212, 212);

    @media screen and (max-width: 980px) {
        justify-content: center;
        flex-direction: column;
    }
}

.nav-container a {
    text-decoration: none;
    color: inherit;
    margin: 0 1em;
    text-transform: uppercase;
    font-family: Moonshine;
    letter-spacing: .1em;
    color: $dark-blue;
    background-color: white;
}

.schedule-btn-nav {
    background: linear-gradient(90deg, $light-orange 0%, $dark-orange 100%);
    padding: .5em 1em;
    border-radius: 5px;
    text-transform: uppercase;
    color: white !important;
    transition: all .2s ease-in-out;

    @media screen and (max-width: 980px) {
        display: none;
    }
}

.navlink {
    @media screen and (max-width: 980px) {
        display: none;
    }
}

.schedule-btn-nav:hover {
    filter: brightness(.75);
}

.link-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5em;

    @media screen and (max-width: 980px) {
        margin: 0;
    }
}

.link-group.left a {
    transition: all .2s ease-in-out;
}

.link-group.left a:hover {
    opacity: 70%;
}

.link-group.right {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media screen and (max-width: 980px) {
        flex-direction: row;
    }
}

.link-group.right p {
    font-size: .8em;
    margin: .5em;
    opacity: 70%;
}

.camden-logo {
    height: 100px;
    margin: 0 2em 0 0;

    @media screen and (max-width: 980px) {
        margin: 0 0 .5em 0;
        height: 150px;
    }
}